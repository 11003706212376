;(function($) {
  let $curriculum, speed
  speed = 300
  $curriculum = $('#curriculum')

  $("a[href^='http']")
    .not("[href^='http://" + location.hostname + "']")
    .attr('target', '_blank')
    .attr('rel', 'noreferrer')

  $("a[href^='#curriculum']").on('click', function(e) {
    e.preventDefault()
    $curriculum.velocity('scroll', {
      duration: speed / 2,
      easing: 'linear',
    })
  })
})(jQuery)
